import { useDispatch, useSelector } from "react-redux";
import { showPopup } from "/redux/action-creaters/popup";
import { popupTypes } from "/redux/reducers/popupReducer";
import React, { useEffect, useMemo, useState } from "react";
import {
  getDeliveryCityData,
  setLocation,
} from "/redux/action-creaters/location";
import Link from "next/link";
import Search from "/components/layouts/baltex/search";
import Catalog from "/components/layouts/baltex/catalog";
import Button from "/components/common/button";
import Icon from "/components/common/icon";
import clsx from "clsx";
import { setShowMobileMenu } from "/redux/action-creaters/pageState";
import Cookies from "js-cookie";
import Menu from "../menu";
import styles from "./index.module.scss";
import { Api } from "../../../../api";

export default React.memo(function Header({ contactsData, isScrolled }) {
  const dispatch = useDispatch();
  const { cityName } = useSelector((state) => state.location);
  const { isShowMobileMenu } = useSelector((state) => state.pageState);
  const [searchActivate, setSearchActive] = useState(0);
  const [detectedCity, setDetectedCity] = useState(null);
  const [doSetDetectedCity, setDoSetDetectedCity] = useState(false);

  useEffect(() => {
    if (typeof window !== "object") {
      return;
    }

    if (!cityName) {
      // eslint-disable-next-line no-use-before-define
      getCity();
    }
  }, [cityName]);

  const getCity = () => {
    const cookieCityName = Cookies.get("cityName");
    const isRobot =
      window.navigator?.userAgent?.includes("yandex") ||
      window.navigator?.userAgent?.includes("google");
    if (!cookieCityName && !isRobot) {
      dispatch(setLocation("Санкт-Петербург"));
      // eslint-disable-next-line no-use-before-define
      detectCity();
    } else {
      dispatch(setLocation(cookieCityName));
    }
  };

  const detectCity = async () => {
    const detectedCity = await Api.dadata.detectCity();
    if (cityName !== detectedCity?.city) {
      setDetectedCity(detectedCity);
    }
  };

  useEffect(() => {
    if (detectedCity && doSetDetectedCity) {
      setDoSetDetectedCity(false);
      dispatch(setLocation(detectedCity));
    }
  }, [detectedCity, doSetDetectedCity]);

  useEffect(() => {
    const body = document.querySelector("body");
    if (!detectedCity) {
      body.classList.remove("popup");
    } else {
      body.classList.add("popup");
    }
  }, [detectedCity]);

  const phone = useMemo(() => {
    return contactsData?.PHONE;
  }, [contactsData]);

  return (
    <header>
      {detectedCity?.city && (
        <noindex>
          <div
            className={styles.Wrapper}
            onClick={() => {
              setDetectedCity("");
            }}
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className={styles.DetectedPopup}
            >
              <div className={styles.DetectedCity}>
                Ваш город {detectedCity?.city}?
              </div>
              <div className={styles.DetectedButtons}>
                <Button
                  onClick={async () => {
                    await dispatch(getDeliveryCityData(detectedCity, true));
                    await dispatch(setLocation(detectedCity.city));

                    setDetectedCity("");
                  }}
                  label={"Да, верно"}
                />
                <Button
                  onClick={() => {
                    dispatch(showPopup(popupTypes.locationNew));
                    setDetectedCity("");
                  }}
                  type={"outline"}
                  label={"Выбрать другой"}
                />
              </div>
            </div>
          </div>
        </noindex>
      )}
      <div className="container">
        <div className={styles.HeaderWpapper}>
          <div className={styles.Header}>
            <div className="container">
              <div
                className={clsx(
                  styles.Top,
                  isScrolled && styles.TopScrolled,
                  isShowMobileMenu && styles.TopMobiled,
                )}
              >
                <div>
                  <div className={styles.TopLeft}>
                    <div
                      className={styles.Location}
                      onClick={() =>
                        dispatch(showPopup(popupTypes.locationNew))
                      }
                    >
                      <div className={styles.City}>{cityName}</div>
                    </div>

                    <div className={styles.Phone}>
                      <a href={`tel:${phone}`} onClick={() => {}}>
                        {phone}
                      </a>
                    </div>
                  </div>

                  <Menu cityName={cityName} />
                </div>
              </div>
              <div className={styles.BodyWrapper}>
                <div
                  className={clsx(
                    styles.Body,
                    isScrolled && styles.BodyFixed,
                    isShowMobileMenu && styles.BodyMobileMenu,
                  )}
                >
                  <div>
                    <div className={clsx(styles.Icons, styles.IconsMobile)}>
                      <div
                        className={clsx(
                          styles.Menu,
                          isShowMobileMenu && styles.MenuActive,
                        )}
                        onClick={() => {
                          dispatch(setShowMobileMenu(!isShowMobileMenu));
                        }}
                      >
                        <div>
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      </div>
                    </div>
                    <Link href="/">
                      <a className={styles.Logo}>
                        <img src="/baltex/logo.svg" />
                        <span>НАДЕЖНЫЕ ФАРКОПЫ</span>
                      </a>
                    </Link>
                    <Catalog isScrolled={isScrolled} />
                    <div
                      className={clsx(
                        styles.SearchDesktop,
                        searchActivate && styles.SearchDesktopActivate,
                        styles.Icons,
                        styles.Icons,
                        isShowMobileMenu && styles.IconsMenuActive,
                        isScrolled && styles.IconsScrolled,
                      )}
                    >
                      <Search
                        activate={searchActivate}
                        isScrolled={isScrolled}
                      />
                    </div>
                    <div className={clsx(styles.Icons, styles.IconsMobile)}>
                      <Button
                        type={"none"}
                        onClick={() => {
                          setSearchActive((i) => i + 1);
                        }}
                        before={
                          <Icon
                            id="search"
                            stroke={"#9294A9"}
                            strokeWidth={2}
                            size={24}
                          />
                        }
                        customStyles={clsx(
                          styles.ButtonMobile,
                          isShowMobileMenu && styles.ButtonMobileActive,
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
});
