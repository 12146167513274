import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import styles from "./index.module.scss";
import BaltexSections from "../../../baltex/sections";
import { getBaltexSections } from "../../../../redux/action-creaters/baltex";

export default function TopSections() {
  const { sections } = useSelector((state) => state.baltex);
  const dispatch = useDispatch();

  useEffect(() => {
    if (sections.length === 0) {
      dispatch(getBaltexSections());
    }
  }, []);

  return (
    <div className={styles.TopSections}>
      <div className="container">
        <BaltexSections />
      </div>
    </div>
  );
}
