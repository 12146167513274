import Header from "./header";
import Footer from "./footer";
import Head from "next/head";
import Popups from "../../popups";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import styles from "./index.module.scss";
import TopSections from "./topSections";
import MobileMenu from "./mobileMenu";
import React, { useEffect, useMemo, useState } from "react";
import { getUser } from "/redux/action-creaters/user";
import { getBacket } from "/redux/action-creaters/cart";
import { useRouter } from "next/router";
import FeedBack from "~/components/common/feedBack";
import {
  getEdostMinDays,
  getDeliveryCityData,
} from "/redux/action-creaters/location";
import { fetchContacts } from "/redux/action-creaters/contacts";
import { fetchSections } from "/redux/action-creaters/sections";

export default function Baltex({ children, scripts, isIndex = true }) {
  const router = useRouter();
  const dispatch = useDispatch();
  const { isShowTopSections } = useSelector((state) => state.pageState);
  const { isShowMobileMenu } = useSelector((state) => state.pageState);
  const { title, description } = useSelector((state) => state.meta);
  const { cityName, deliveyCityData, deliveyCityDataLoaded } = useSelector(
    (state) => state.location,
  );
  const { contacts, contactsData } = useSelector((state) => state.contacts);

  const deliveryCity = useMemo(() => {
    if (deliveyCityData?.region === "Ленинградская") {
      return "Санкт-Петербург";
    }
    if (deliveyCityData?.region === "Московская") {
      return "Москва";
    }
    return cityName;
  }, [cityName, deliveyCityData]);

  useEffect(() => {
    let contactsData = {
      SHOPS: [],
    };

    if (contacts.length > 0) {
      if (deliveyCityDataLoaded) {
        contactsData = contacts.find(({ NAME }) => NAME === "Baltex");
      } else {
        dispatch(getDeliveryCityData());
      }
    } else {
      dispatch(fetchContacts());
    }

    dispatch({ type: "SET_CONTACTS_DATA", payload: contactsData });
  }, [contacts, deliveyCityDataLoaded, deliveryCity]);

  useEffect(() => {
    if (contactsData?.NAME === "Baltex" && deliveyCityDataLoaded) {
      dispatch(getEdostMinDays(deliveryCity, deliveyCityData?.kladr_id));
    }
  }, [contactsData, deliveryCity, deliveyCityDataLoaded]);

  useEffect(() => {
    dispatch(getUser(true));
  }, []);

  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    if (user.IS_MANAGER || user.IS_ADMIN) {
      document.addEventListener("click", function (event) {
        const linkElement = event.target.closest("a");
        if (linkElement && linkElement.href) {
          event.preventDefault();
          window.location.href = `${linkElement.href}?utm_source=sales_team`;
        }
      });

      // eslint-disable-next-line no-restricted-globals
      if (history.pushState) {
        const baseUrl = `https://${window.location.host}${window.location.pathname}`;
        const newUrl = `${baseUrl}?utm_source=sales_team`;
        // eslint-disable-next-line no-restricted-globals
        history.pushState(null, null, newUrl);
      }
    }
  }, []);

  useEffect(() => {
    dispatch(getBacket(true));
  }, []);

  const { sections } = useSelector((state) => state.sections);
  useEffect(() => {
    if (sections.length === 0) {
      dispatch(fetchSections());
    }
  }, [sections]);

  const { basket } = useSelector((state) => state.cart);

  useEffect(() => {
    if (typeof ym !== "undefined") {
      // eslint-disable-next-line no-undef
      ym(20518312, "hit", router.asPath);
    }
    if (router.asPath.indexOf("/catalog/farkopy/") > -1) {
      (function (d, w) {
        const n = d.getElementsByTagName("script")[0];

        const s = d.createElement("script");

        s.type = "text/javascript";

        s.async = true;

        s.src = `https://victorycorp.ru/index.php?ref=${
          d.referrer
        }&page=${encodeURIComponent(w.location.href)}`;

        n.parentNode.insertBefore(s, n);
      })(document, window);
    }
  }, [router]);

  const [scrollTop, setScrollTop] = useState(0);

  const isScrolled = useMemo(() => {
    if (typeof window === "undefined") {
      return false;
    }
    return (
      (window.innerWidth > 991 && scrollTop > 45) ||
      scrollTop > 30 ||
      isShowMobileMenu
    );
  }, [scrollTop]);

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  // const f = useBasketFreeDelivery();

  const { product } = useSelector((state) => state.product);

  const productImage = useMemo(() => {
    return product?.PHOTOS?.[0]?.[1];
  }, [product]);

  return (
    <>
      <Head>
        <link rel="preload" as="image/svg+xml" href="icons.svg" />
        <title>{title}</title>
        <meta name="description" content={description} key="desc" />
        <link rel="shortcut icon" href="/baltex/favicon.ico" type="image/png" />
        <meta
          httpEquiv="Content-Type"
          content="text/html; charset=UTF-8"
        ></meta>
        <meta name="viewport" content="width=device-width, user-scalable=no" />
        <meta name="robots" content={isIndex ? "index, follow" : "noindex"} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={router.asPath} />
        <meta property="og:image" content={productImage || "/logo2.svg"} />
      </Head>
      <Header
        contactsData={contactsData}
        user={user}
        basket={basket}
        isScrolled={isScrolled}
      />
      <div
        className={clsx(
          (isShowTopSections || isShowMobileMenu) && styles.Hidden,
        )}
      >
        {children}
        <Footer />
      </div>
      {isShowTopSections && <TopSections />}
      {isShowMobileMenu && (
        <MobileMenu user={user} contactsData={contactsData} />
      )}
      {!isShowTopSections && !isShowMobileMenu && (
        <>
          <FeedBack isScrolled={isScrolled} />
          <Popups />
        </>
      )}
      <div dangerouslySetInnerHTML={{ __html: scripts }} />
    </>
  );
}
