import Button from "/components/common/button";
import { useDispatch, useSelector } from "react-redux";
import { setShowTopSections } from "/redux/action-creaters/pageState";
import styles from "./index.module.scss";
import clsx from "clsx";

export default function Catalog({ isScrolled, isLanding = false }) {
  const dispatch = useDispatch();
  const { isShowTopSections } = useSelector((state) => state.pageState);

  const toggleTopCatalog = () => {
    dispatch(setShowTopSections(!isShowTopSections));
  };

  return (
    <Button
      label="Каталог"
      onClick={toggleTopCatalog}
      type={isShowTopSections ? "blackSelected" : "blue"}
      before={
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="4" y="4" width="3" height="3" fill="white" />
          <rect x="4" y="10" width="3" height="3" fill="white" />
          <rect x="4" y="16" width="3" height="3" fill="white" />
          <rect x="10" y="4" width="3" height="3" fill="white" />
          <rect x="10" y="10" width="3" height="3" fill="white" />
          <rect x="10" y="16" width="3" height="3" fill="white" />
          <rect x="16" y="4" width="3" height="3" fill="white" />
          <rect x="16" y="10" width="3" height="3" fill="white" />
          <rect x="16" y="16" width="3" height="3" fill="white" />
        </svg>
      }
      customStyles={clsx(
        styles.CatalogButton,
        isScrolled && styles.CatalogButtonScrolled,
        isLanding && styles.CatalogButtonLanding,
      )}
      isLanding={isLanding}
    />
  );
}
