import Landings from "components/layouts/landings";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import { SiteTypes } from "~/redux/reducers/paramsReducer";

import Baltex from "./baltex";
import Layout from "./main";

export default function LayoutWrapper({
  host,
  children,
  scripts,
  isIndex = true,
  isMain = false,
}: any) {
  const { siteType } = useSelector((state: any) => state.params);

  useEffect(() => {
    if (
      typeof sessionStorage !== "undefined" &&
      typeof window !== "undefined"
    ) {
      sessionStorage.setItem("previosUrl", window.location?.href);
    }
  }, []);

  return (
    <>
      {siteType === SiteTypes.Baltex && (
        <Baltex scripts={scripts} isIndex={isIndex}>
          {children}

          <span
            dangerouslySetInnerHTML={{
              __html: `
                 <style>
                    :root {
                        --font: 'Gilroy';
                        --main-theme: #09080A;
                        --main-theme-2: #09080A;
                        --link-color: #0FB1B1;
                        --main-theme-hover: #333;
                        --main-theme-text: #fff;
                        --main-theme-border-radius: 0px;
                        --main-theme-back: #F2F2F2;
                    }
                </style>
                `,
            }}
          />
        </Baltex>
      )}
      {siteType === SiteTypes.Landing && (
        <Landings isMain={isMain} isIndex={isIndex} isLanding>
          {children}

          <span
            dangerouslySetInnerHTML={{
              __html: `
                 <style>
                    :root {
                        --font: 'Manrope';
                        --main-theme: #FADE74;
                        --main-theme-2: #09080A;
                        --link-color: #09080A;
                        --main-theme-hover: #ffd842;
                        --main-theme-text: #09080A;
                        --main-theme-border-radius: 4px;
                        --main-theme-back: #F2F2F2;
                    }
                </style>
                `,
            }}
          />
        </Landings>
      )}
      {siteType === SiteTypes.Farkop && (
        <Layout scripts={scripts} host={host} isIndex={isIndex}>
          {children}

          <span
            dangerouslySetInnerHTML={{
              __html: `
                <style>
                    :root {
                        --font: 'Golos Text';
                        --main-theme: #1247a5;
                        --main-theme-2: #1247a5;
                        --link-color: #1247a5;
                        --main-theme-hover: #0634B0;
                        --main-theme-text: #fff;
                        --main-theme-border-radius: 4px;
                        --main-theme-back: #fff;
                    }
                </style>
                `,
            }}
          />
        </Layout>
      )}
    </>
  );
}
