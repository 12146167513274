import Link from "next/link";
import styles from "./Footer.module.scss";

export default function Footer() {
  return (
    <div className={styles.Footer}>
      <div className="container">
        <div className={styles.Colomns}>
          <div className={styles.Info}>
            <Link href="/">
              <a className={styles.Logo}>
                <img src="/baltex/logoFooter.svg" />
                <span>НАДЕЖНЫЕ ФАРКОПЫ</span>
              </a>
            </Link>
          </div>
          <ul>
            <li>
              <Link href="/reviews/">Отзывы</Link>
            </li>
            <li>
              <Link href="/info/">Для покупателей</Link>
            </li>
            <li>
              <Link href="/about/">О компании</Link>
            </li>
            <li>
              <Link href="/contacts/">Контакты</Link>
            </li>
          </ul>
        </div>

        <div className={styles.Privacy}>
          <Link href="/privacy/">Пользовательское соглашение</Link>
        </div>

        <div className={styles.Copy}>
          <div>© 2010–{new Date().getFullYear()} Baltex.ru</div>
          <div className={styles.Payments}>
            <img src="/payments/1.png" />
            <img src="/payments/2.png" />
            <img src="/payments/3.png" />
            <img src="/payments/4.png" />
            <img src="/payments/5.png" />
            <img src="/payments/7.png" />
            <img src="/payments/8.png" />
          </div>
        </div>
        <div className={styles.Info}>
          Вся информация на данном сайте несёт исключительно информационный
          характер и ни при каких условиях не является публичной офертой,
          определяемой положениями Статьи 437 (2) ГК РФ.
        </div>
      </div>
    </div>
  );
}
