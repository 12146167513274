import Link from "next/link";
import styles from "./index.module.scss";

export default function Menu() {
  return (
    <ul className={styles.Links}>
      <li>
        <Link href="/services/">Услуги</Link>
      </li>
      <li>
        <Link href="/articles/">Статьи и новости</Link>
      </li>
      <li>
        <Link href="/contacts/">Контакты</Link>
      </li>
    </ul>
  );
}
